import React from "react"
import { Link } from "@reach/router"
import styled from "@emotion/styled"

import SEO from "../components/seo"
import Layout from "../components/ui/layout"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 1rem;
`

const Title = styled.h2`
  font-family: "Open Sans";
  text-align: center;
  font-size: 24px;
  line-height: 116%;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
`

const CodeError = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  line-height: 116%;
  margin: 0;
  color: #ffffff;
`

const Description = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 116%;
  text-align: center;
  color: #ffffff;
`

const GoHome = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #5d5d5d;
  border-radius: 6px;
  text-decoration: none;
  padding: 0.75rem 0;
  min-width: 130px;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  padding: calc(0.75rem + 1px) 0;
  border: 0;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
    border: 0;
  }
`

const NotFoundPage = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="¡UPS! No hay nada por acá" />

      <Container>
        <Title>Lo sentimos, no hay nada por acá</Title>
        <CodeError>404</CodeError>
        <Description>Sigue navegando desde nuestro home</Description>
        <GoHome to="/">VOLVER AL HOME</GoHome>
      </Container>
    </Layout>
  </QueryStringProvider>
)

export default NotFoundPage
